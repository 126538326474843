var render = function () {
  var _vm$status, _vm$status2, _vm$status3, _vm$status4, _vm$status5, _vm$status6, _vm$status7, _vm$status8, _vm$status9, _vm$status10;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-circle-status-button"
  }, [!_vm.isAutenti ? [_vm.status.toUpperCase() === _vm.Statues.active ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/accept.svg")
    }
  }) : _vm._e(), _vm.status.toUpperCase() === _vm.Statues.rejected ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/reject.svg")
    }
  }) : _vm._e(), _vm.status.toUpperCase() === _vm.Statues.closed ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/accept.svg")
    }
  }) : _vm._e(), _vm.status.toUpperCase() === _vm.Statues.awaiting ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/question.svg")
    }
  }) : _vm._e(), _vm.status.toUpperCase() === _vm.Statues.pending || _vm.status.toUpperCase() === _vm.Statues.new ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/pending.svg")
    }
  }) : _vm._e(), _vm.status.toUpperCase() === _vm.Statues.declined ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/decline.svg")
    }
  }) : _vm._e()] : [((_vm$status = _vm.status) === null || _vm$status === void 0 ? void 0 : _vm$status.toUpperCase()) === 'PROCESSING' || ((_vm$status2 = _vm.status) === null || _vm$status2 === void 0 ? void 0 : _vm$status2.toUpperCase()) === 'DRAFT' || ((_vm$status3 = _vm.status) === null || _vm$status3 === void 0 ? void 0 : _vm$status3.toUpperCase()) === 'UNKNOWN' ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/pending.svg")
    }
  }) : _vm._e(), ((_vm$status4 = _vm.status) === null || _vm$status4 === void 0 ? void 0 : _vm$status4.toUpperCase()) === 'COMPLETED' ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/accept.svg")
    }
  }) : _vm._e(), ((_vm$status5 = _vm.status) === null || _vm$status5 === void 0 ? void 0 : _vm$status5.toUpperCase()) === 'ERROR' ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/reject.svg")
    }
  }) : _vm._e(), !(((_vm$status6 = _vm.status) === null || _vm$status6 === void 0 ? void 0 : _vm$status6.toUpperCase()) === 'PROCESSING' || ((_vm$status7 = _vm.status) === null || _vm$status7 === void 0 ? void 0 : _vm$status7.toUpperCase()) === 'DRAFT' || ((_vm$status8 = _vm.status) === null || _vm$status8 === void 0 ? void 0 : _vm$status8.toUpperCase()) === 'UNKNOWN' || ((_vm$status9 = _vm.status) === null || _vm$status9 === void 0 ? void 0 : _vm$status9.toUpperCase()) === 'COMPLETED' || ((_vm$status10 = _vm.status) === null || _vm$status10 === void 0 ? void 0 : _vm$status10.toUpperCase()) === 'ERROR') ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/decline.svg")
    }
  }) : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }