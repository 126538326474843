var render = function () {
  var _vm$report, _vm$report2, _vm$report3, _vm$report4, _vm$report5, _vm$report6, _vm$report7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketplace-filters filters-container filters table"
  }, [(_vm$report = _vm.report) !== null && _vm$report !== void 0 && _vm$report.name ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h2', {
    staticClass: "filters__header title"
  }, [_vm._v(_vm._s(_vm.report.name) + " - " + _vm._s(_vm._f("formatPeriod")(_vm.nextDate, (_vm$report2 = _vm.report) === null || _vm$report2 === void 0 ? void 0 : _vm$report2.periodType)))])])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_vm.showLePicker ? _c('div', {
    staticClass: "col-2 col-md-4 col-xl-2"
  }, [_c('sygni-context-picker', {
    on: {
      "setViewedId": _vm.setViewedId
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "col-2 col-md-4 col-xl-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.scenarioOptions,
      "allow-empty": false,
      "value": (_vm$report3 = _vm.report) === null || _vm$report3 === void 0 ? void 0 : _vm$report3.scenario,
      "label": "SCENARIO",
      "disabled": _vm.editMode
    },
    on: {
      "input": _vm.changeScenario
    }
  })], 1), _c('div', {
    staticClass: "col-2 col-md-4 col-xl-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.periodOptions,
      "allow-empty": false,
      "value": (_vm$report4 = _vm.report) === null || _vm$report4 === void 0 ? void 0 : _vm$report4.periodType,
      "label": "PERIOD TYPE"
    },
    on: {
      "input": _vm.changePeriod
    }
  })], 1), _c('div', {
    staticClass: "col-2 col-md-4 col-xl-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.rangeOptions,
      "allow-empty": false,
      "value": (_vm$report5 = _vm.report) === null || _vm$report5 === void 0 ? void 0 : _vm$report5.rangeType,
      "label": "RANGE TYPE"
    },
    on: {
      "input": _vm.changeRange
    }
  })], 1), _c('div', {
    class: _vm.showLePicker ? 'col-2 col-md-4 col-xl-2' : 'col-2 col-md-6 col-xl-2'
  }, [_c('div', {
    staticClass: "label"
  }), _c('div', {
    staticClass: "range-switcher"
  }, [_c('div', {
    staticClass: "range-switcher__btn",
    on: {
      "click": _vm.prevHandler
    }
  }, [_c('div', {
    staticClass: "range-switcher__btn-icon"
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": _vm._f("getIcon")('DB ARROW LEFT')
    }
  })])]), _c('div', {
    staticClass: "range-switcher__value"
  }, [_vm._v(" " + _vm._s(_vm._f("formatPeriod")(_vm.prevDate, (_vm$report6 = _vm.report) === null || _vm$report6 === void 0 ? void 0 : _vm$report6.periodType)) + " - " + _vm._s(_vm._f("formatPeriod")(_vm.nextDate, (_vm$report7 = _vm.report) === null || _vm$report7 === void 0 ? void 0 : _vm$report7.periodType)) + " ")]), _c('div', {
    staticClass: "range-switcher__btn",
    on: {
      "click": _vm.nextHandler
    }
  }, [_c('div', {
    staticClass: "range-switcher__btn-icon"
  }, [_c('img', {
    attrs: {
      "alt": "Next",
      "src": _vm._f("getIcon")('DB ARROW RIGHT')
    }
  })])])])]), _vm.showActions ? _c('div', {
    class: _vm.showLePicker ? 'col-2 col-md-4 col-xl-2' : 'col-4 col-md-6 col-xl-4'
  }, [_c('div', {
    staticClass: "label"
  }), _c('div', {
    staticClass: "filters__actions"
  }, [!_vm.editMode ? _c('sygni-rect-button', {
    staticClass: "outline",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Go back")]) : _vm._e(), _vm.editMode ? _c('sygni-rect-button', {
    staticClass: "outline",
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm._v("Cancel")]) : _vm._e(), _c('sygni-rect-button', {
    staticClass: "filled black fixed-width",
    on: {
      "click": function click($event) {
        return _vm.$emit('edit');
      }
    }
  }, [_vm._v(_vm._s(!_vm.editMode ? 'Edit' : 'Save') + " Report")]), _vm.editMode ? _c('sygni-rect-button', {
    staticClass: "filled red",
    on: {
      "click": function click($event) {
        return _vm.$emit('publish');
      }
    }
  }, [_vm._v("Publish Report")]) : _vm._e()], 1)]) : _vm._e(), !_vm.showActions ? _c('div', {
    class: _vm.showLePicker ? 'col-2 col-md-4 col-xl-2' : 'col-4 col-md-6 col-xl-4'
  }, [_c('div', {
    staticClass: "label"
  }), _c('div', {
    staticClass: "filters__actions"
  }, [!_vm.editMode ? _c('sygni-rect-button', {
    staticClass: "outline",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Go back")]) : _vm._e()], 1)]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }