var render = function () {
  var _vm$otherSections, _vm$tableSections;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reporting-module"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "reporting-module__toolbar"
  }, [_vm.showLePicker && !_vm.isReportOpened ? _c('div', {
    staticClass: "container-fluid mt-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4 offset-8"
  }, [_c('sygni-context-picker', {
    on: {
      "setViewedId": _vm.checkReportingAvailability
    }
  })], 1)])]) : _vm._e()]), _c('div', {
    staticClass: "reporting-module__container"
  }, [!_vm.isReportOpened ? _c('div', {
    staticClass: "reporting-module__box"
  }, [_c('sygni-container-title', [_vm._v("Dynamic reports")]), _c('reporting-form-table', {
    staticClass: "mb-4",
    attrs: {
      "tableItems": _vm.reportFormPeriods
    },
    on: {
      "loadReport": function loadReport($event) {
        return _vm.loadReport($event, true);
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }], null, false, 1648903300)
  }), _vm.hasStaticReports ? [_c('sygni-container-title', [_vm._v("Static reports")]), _c('static-reports-table', {
    attrs: {
      "tableItems": _vm.staticReports
    },
    on: {
      "loadReport": _vm.enableReportModal
    }
  }), _c('attachment-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showReportModal,
      expression: "showReportModal"
    }],
    attrs: {
      "size": "large",
      "download": true,
      "attachment": _vm.selectedReport
    },
    on: {
      "close": _vm.closeReportModal,
      "download": _vm.downloadSelectedReportFile
    }
  })] : _vm._e()], 2) : _vm._e(), _vm.isReportOpened ? _c('div', {
    staticClass: "reporting-module__inner",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_c('div', {
    staticClass: "reporting-module__body"
  }, [_c('div', {
    staticClass: "reporting-module__filters"
  }, [_c('reporting-filters', {
    attrs: {
      "showLePicker": _vm.showLePicker,
      "editMode": _vm.editMode,
      "report": _vm.report,
      "showActions": _vm.showActions,
      "reportCommon": _vm.reportCommon
    },
    on: {
      "changeScenario": _vm.scenarioHandler,
      "changePeriod": _vm.periodHandler,
      "changeRange": _vm.rangeHandler,
      "prev": _vm.prevHandler,
      "next": _vm.nextHandler,
      "edit": _vm.editHandler,
      "cancel": _vm.cancelHandler,
      "publish": _vm.publishHandler,
      "close": _vm.closeReport,
      "setViewedId": _vm.checkReportingAvailability
    }
  })], 1), _c('div', {
    staticClass: "reporting-module__table"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('reporting-table', {
    ref: "reportingTable",
    attrs: {
      "editMode": _vm.editMode,
      "selectedCol": _vm.activeCol,
      "tableFields": _vm.globalHeaderFields,
      "tableSections": _vm.tableSections,
      "scrolledHeight": _vm.scrolledHeight
    },
    on: {
      "publish": _vm.publishHandler,
      "selectCol": _vm.setActiveColumn
    }
  })], 1)])])])]), _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    class: ['reporting-module__sections', (_vm$otherSections = _vm.otherSections) !== null && _vm$otherSections !== void 0 && _vm$otherSections.length ? 'white' : '', !((_vm$tableSections = _vm.tableSections) !== null && _vm$tableSections !== void 0 && _vm$tableSections.length) ? 'without-border' : '']
  }, [_vm._l(_vm.otherSections, function (section) {
    return _c('section', {
      key: section.name,
      staticClass: "section"
    }, [_c('h2', {
      staticClass: "section__title title"
    }, [_vm._v(_vm._s(section.name))]), section.type === 'ROW' ? _vm._l(section.items, function (item) {
      return _c('div', {
        key: item.name,
        staticClass: "section__item"
      }, [item.type === 'TEXT' ? [_vm._l(item.values, function (data, index) {
        return [_vm.activeCol === index ? _c('sygni-input', {
          key: data.id,
          staticClass: "text-left",
          attrs: {
            "disabled": !data.editable,
            "value": data.value
          },
          on: {
            "input": function input($event) {
              return _vm.updateTextValue($event, data.id);
            }
          }
        }) : _vm._e()];
      })] : _vm._e(), item.type === 'TEXTAREA' ? [_vm._l(item.values, function (data, index) {
        return [_vm.activeCol === index ? _c('sygni-text-area', {
          key: data.id,
          attrs: {
            "disabled": !data.editable,
            "value": data.value
          },
          on: {
            "input": function input($event) {
              return _vm.updateTextValue($event, data.id);
            }
          }
        }) : _vm._e()];
      })] : _vm._e(), item.type === 'SELECT' ? [_c('sygni-select', {
        attrs: {
          "disabled": !_vm.editMode,
          "options": []
        }
      })] : _vm._e(), item.type === 'YESNO' ? [_vm._l(item.values, function (data, index) {
        return [_vm.activeCol === index ? _c('div', {
          key: data.id,
          staticClass: "section__radio"
        }, [_c('p', {
          staticClass: "section__radio-text"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "section__radio-btns"
        }, [_c('sygni-radio', {
          attrs: {
            "radioValue": 1,
            "value": data.value,
            "disabled": !data.editable,
            "group": item.name,
            "label": "YES"
          },
          on: {
            "input": function input($event) {
              return _vm.updateRadioValue(1, data.id);
            }
          }
        }), _c('sygni-radio', {
          attrs: {
            "radioValue": 0,
            "value": data.value,
            "disabled": !data.editable,
            "group": item.name,
            "label": "NO"
          },
          on: {
            "input": function input($event) {
              return _vm.updateRadioValue(0, data.id);
            }
          }
        })], 1)]) : _vm._e()];
      })] : _vm._e(), item.type === 'FILES' ? [_vm._l(item.values, function (data, index) {
        var _item$options;

        return [_vm.activeCol === index ? _c('reporting-files-table', {
          key: data.id,
          attrs: {
            "editMode": data.editable,
            "options": Object.values(item !== null && item !== void 0 && (_item$options = item.options) !== null && _item$options !== void 0 && _item$options.choices ? item.options.choices : []),
            "fileTableData": _vm.fileTableData(data)
          },
          on: {
            "addFiles": function addFiles($event) {
              return _vm.addFiles($event, data.id);
            }
          }
        }) : _vm._e()];
      })] : _vm._e()], 2);
    }) : _vm._e()], 2);
  }), !_vm.showLePicker ? _c('div', {
    staticClass: "reporting-module__footer"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "reporting-module__actions"
  }, [_vm.editMode ? _c('sygni-rect-button', {
    staticClass: "outline",
    on: {
      "click": _vm.cancelHandler
    }
  }, [_vm._v("Cancel")]) : _vm._e(), _c('sygni-rect-button', {
    staticClass: "filled black fixed-width",
    on: {
      "click": _vm.editHandler
    }
  }, [_vm._v(_vm._s(!_vm.editMode ? 'Edit' : 'Save') + " Report")]), _vm.editMode ? _c('sygni-rect-button', {
    staticClass: "filled red",
    on: {
      "click": function click($event) {
        return _vm.publishHandler(null);
      }
    }
  }, [_vm._v("Publish Report")]) : _vm._e()], 1)])])]) : _vm._e()], 2), _c('div', {
    staticClass: "reporting-module__charts"
  }, _vm._l(_vm.newGraphs, function (chart, index) {
    return _c('NewChart', {
      key: "newChart-".concat(index),
      attrs: {
        "data": chart
      }
    });
  }), 1)])])])]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }