var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reporting-form-table"
  }, [_vm._t("actions"), _c('div', {
    staticClass: "reporting-form-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "reporting-formTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "items": _vm.tableItems,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "show-empty": true,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "thead-top",
      fn: function fn() {
        return [_vm._t("beforeHead")];
      },
      proxy: true
    }, {
      key: "cell(reportName)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(rowData.item.reportName) + " ")])])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "td__actions"
        }, [_c('div', {
          staticClass: "action",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$emit('loadReport', rowData.item);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('Fund'),
            "alt": ""
          }
        })])])];
      }
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "cell text-center"
        }, [_vm._v("There are no reports created yet.")])];
      },
      proxy: true
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center text-danger spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }