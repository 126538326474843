



















import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import {Statues} from "@/shared/interfaces/Statues";

@Component
export default class SygniCircleStatusButton extends Vue {

  @Prop({ default: false }) isAutenti: boolean;
  @Prop() status!: Statues;
  active: boolean = false;

  readonly Statues = Statues;
}
