var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "static-reports-table"
  }, [_vm._t("actions"), _c('div', {
    staticClass: "static-reports-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "staticReportsTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "items": _vm.tableItems,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "thead-top",
      fn: function fn() {
        return [_vm._t("beforeHead")];
      },
      proxy: true
    }, {
      key: "cell(reportName)",
      fn: function fn(rowData) {
        var _rowData$item;

        return [_c('div', {
          staticClass: "text-left pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s((_rowData$item = rowData.item) === null || _rowData$item === void 0 ? void 0 : _rowData$item.reportName) + " ")])])];
      }
    }, {
      key: "cell(reportDate)",
      fn: function fn(rowData) {
        var _rowData$item2;

        return [_c('div', {
          staticClass: "text-center px-2"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")((_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.reportDate)) + " ")])])];
      }
    }, {
      key: "cell(category)",
      fn: function fn(rowData) {
        var _rowData$item3, _rowData$item4;

        return [_c('div', {
          staticClass: "table__status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: ['outline round', _vm.getCategoryClassName((_rowData$item3 = rowData.item) === null || _rowData$item3 === void 0 ? void 0 : _rowData$item3.category)],
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(_vm._s((_rowData$item4 = rowData.item) === null || _rowData$item4 === void 0 ? void 0 : _rowData$item4.category))])], 1)];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "td__actions"
        }, [_c('div', {
          staticClass: "action",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$emit('loadReport', rowData.item);
            }
          }
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('Fund'),
            "alt": "Preview"
          }
        })])]), _c('div', {
          staticClass: "action",
          on: {
            "click": function click($event) {
              return _vm.downloadFile(rowData.item);
            }
          }
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('DOWNLOAD'),
            "alt": "Download"
          }
        })])])])];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center text-danger spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }